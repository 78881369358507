import React, { useContext, useEffect } from 'react';
import { Bar, BarChart, Line, LineChart, ResponsiveContainer, YAxis } from 'recharts';
import ReactGA4 from 'react-ga4';
import { Grid, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlaceIcon from '@mui/icons-material/Place';

import {
  areaTooltipText,
  priceTooltipText,
  squarePriceTooltipText,
  themes,
  totalPriceTooltipText,
} from '../../../helpers/constants';
import { formatNumber, shortDate } from '../../../helpers/helpers';

import './ReportMain.scss';
import { ThemeContext } from '../../../contexts/ThemeContext';

export default function ReportMain({ data, type, onChangeTab }) {
  const contractsTooltipText = `Novogradnja i Starogradnja predstavljaju ukupan broj prometovanih stanova u ovim kategorijama. 
  Ugovori predstavljaju broj kupoprodajnih ugovora u kojima se nalazi barem jedan stan`;

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    ReactGA4.send({ hitType: 'pageview', page: '/report', title: 'Report' });
  }, []);

  const ReportBox = ({
    title,
    value,
    colorClass = '',
    chart = false,
    bar = false,
    chartColor,
    eur = false,
    m2 = false,
    compact = false,
    big = false,
    mb = false,
    mapLink = false,
  }) => {
    const number = data[data.length - 1][value];
    return (
      <div className={`report-box${big ? ' big' : ''}${mb ? ' mb' : ''}`}>
        <h2 className={`subtitle ${colorClass}`}>{title}</h2>
        <div className='value-container'>
          {Number.isFinite(number) ? (
            <div className={`value${eur & m2 ? ' square-price' : ''}`}>
              <span>{eur ? formatNumber(number, compact) : number}</span>
              <span className='smaller'>{eur ? '€' : ''}</span>
              <span className='smaller'>{eur && m2 ? '/' : ''}</span>
              <span className='smaller'>{m2 ? 'm²' : ''}</span>
              {mapLink && number > 0 && (
                <div className='map-link' onClick={() => onChangeTab('map', value)}>
                  <IconButton
                    sx={{ flexDirection: 'column', alignItems: 'center', borderRadius: '15px' }}
                  >
                    <PlaceIcon
                      sx={{
                        color: themes[theme].main,
                        fontSize: useMediaQuery('(max-width: 499px)') ? '40px' : '60px',
                      }}
                    />
                    <div>Vidi na mapi</div>
                  </IconButton>
                </div>
              )}
            </div>
          ) : (
            <span>-</span>
          )}
          {chart && Number.isFinite(number) && !bar && (
            <div className='value-chart' onClick={() => onChangeTab('charts', value)}>
              <TinyLineChart value={value} chartColor={chartColor} big={big} />
            </div>
          )}
          {chart && bar && (
            <div className='value-chart' onClick={() => onChangeTab('charts', value)}>
              <TinyBarChart value={value} chartColor={chartColor} />
            </div>
          )}
        </div>
        <div className='comparisons'>
          {data[data.length - 2] && (
            <Comparison
              value={number}
              compareValue={data[data.length - 2][value]}
              date={data[data.length - 2].id}
              eur={eur}
              compact={compact}
              m2={m2}
            />
          )}
          {data[data.length - (type === 'monthly' ? 13 : 5)] && (
            <Comparison
              value={number}
              compareValue={data[data.length - (type === 'monthly' ? 13 : 5)][value]}
              date={data[data.length - (type === 'monthly' ? 13 : 5)].id}
              eur={eur}
              compact={compact}
              m2={m2}
            />
          )}
        </div>
      </div>
    );
  };

  const Comparison = ({ value, compareValue, date, eur = false, compact = false, m2 = false }) => {
    const diff = Math.round(10 * ((100 * (value - compareValue)) / compareValue)) / 10;

    return (
      <div className='comparison-container'>
        <ComparisonPercent diff={diff} />
        <div>
          {Number.isFinite(compareValue) ? (
            <>
              <span className='comparison-date'>&nbsp;&#x2022;&nbsp;{shortDate(date)}: </span>
              <span className='bolder'>
                {eur ? formatNumber(compareValue, compact) : compareValue}
              </span>
              <span className='smaller'>{eur ? '€' : ''}</span>
              <span className='smaller'>{eur && m2 ? '/' : ''}</span>
              <span className='smaller'>{m2 ? 'm²' : ''}</span>
            </>
          ) : (
            <span>&nbsp;&#x2022;&nbsp;{shortDate(date)}: -</span>
          )}
        </div>
      </div>
    );
  };

  const ComparisonPercent = ({ diff }) => {
    switch (Math.sign(diff)) {
      case 1:
        return (
          <div className='diff up'>
            <TrendingUpIcon sx={{ fontSize: 22, marginRight: 0.5 }} />
            <div>{Number.isFinite(diff) ? diff : '∞'}%</div>
          </div>
        );
      case -1:
        return (
          <div className='diff down'>
            <TrendingDownIcon sx={{ fontSize: 22, marginRight: 0.5 }} />
            <div>{Math.abs(diff)}%</div>
          </div>
        );
      case 0:
        return (
          <div className='diff flat'>
            <TrendingFlatIcon sx={{ fontSize: 22, marginRight: 0.5 }} />
            <div>{diff}%</div>
          </div>
        );
      default:
        return <div className='diff'>-</div>;
    }
  };

  const TinyLineChart = ({ value, chartColor, big }) => {
    return (
      <ResponsiveContainer width='99%' height={70}>
        <LineChart data={data}>
          <YAxis hide={true} domain={['dataMin', 'dataMax']} />
          <Line
            dot={<EndDot big={big} />}
            connectNulls
            dataKey={value}
            stroke={chartColor}
            strokeWidth={big ? 4 : 3}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const TinyBarChart = ({ value, chartColor }) => {
    return (
      <ResponsiveContainer width='99%' height={70}>
        <BarChart data={data}>
          <Bar dataKey={value} fill={chartColor} radius={[6, 6, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const EndDot = ({ cx, cy, stroke, payload, big = false }) => {
    const size = big ? 4 : 3;
    if (payload.id === data[data.length - 1].id) {
      return (
        <svg x={cx - size} y={cy - size} width={size * 2} height={size * 2}>
          <g transform={`translate(${size} ${size})`}>
            <circle r={size} fill={stroke} />
          </g>
        </svg>
      );
    }
  };

  return (
    <div className='report-main'>
      <div className='title'>
        <h1>Cena kvadrata</h1>
        <Tooltip title={<Typography fontSize={14}>{squarePriceTooltipText}</Typography>}>
          <InfoOutlinedIcon />
        </Tooltip>
      </div>
      <Grid container spacing={1} alignItems='center' justifyContent='space-around'>
        <Grid item xs={12} sm={10} md={6}>
          <ReportBox
            title='Novogradnja'
            value='newSquarePrice'
            chart={true}
            chartColor={themes[theme].new}
            colorClass='new'
            eur={true}
            m2={true}
            mb={true}
          />
          <ReportBox
            title='Starogradnja'
            value='oldSquarePrice'
            chart={true}
            chartColor={themes[theme].old}
            colorClass='old'
            eur={true}
            m2={true}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={6}>
          <ReportBox
            title='Prosek'
            value='squarePrice'
            chart={true}
            chartColor={themes[theme].main}
            eur={true}
            m2={true}
            big={true}
          />
        </Grid>
      </Grid>
      <div className='title'>
        <h2>Broj prometa</h2>
        <Tooltip title={<Typography fontSize={14}>{contractsTooltipText}</Typography>}>
          <InfoOutlinedIcon />
        </Tooltip>
      </div>
      <Grid container spacing={1} alignItems='center' justifyContent='space-around'>
        <Grid item xs={12} sm={10} md={6}>
          <ReportBox
            title='Novogradnja'
            value='newApartmentsCount'
            chart={true}
            chartColor={themes[theme].new}
            bar={true}
            colorClass='new'
            mb={true}
          />
          <ReportBox
            title='Starogradnja'
            value='oldApartmentsCount'
            chart={true}
            chartColor={themes[theme].old}
            bar={true}
            colorClass='old'
          />
        </Grid>
        <Grid item xs={12} sm={10} md={6}>
          <ReportBox
            title='Ugovori'
            value='totalCount'
            chart={true}
            chartColor={themes[theme].main}
            bar={true}
            big={true}
            mapLink={true}
          />
        </Grid>
      </Grid>
      <div className='title'>
        <h2>Cena stana</h2>
        <Tooltip title={<Typography fontSize={14}>{priceTooltipText}</Typography>}>
          <InfoOutlinedIcon />
        </Tooltip>
      </div>
      <Grid container spacing={1} alignItems='center' justifyContent='space-around'>
        <Grid item xs={12} sm={7} md={4}>
          <ReportBox title='Novogradnja' value='newPrice' colorClass='new' eur={true} />
        </Grid>
        <Grid item xs={12} sm={7} md={4}>
          <ReportBox title='Starogradnja' value='oldPrice' colorClass='old' eur={true} />
        </Grid>
        <Grid item xs={12} sm={7} md={4}>
          <ReportBox title='Prosek' value='price' eur={true} />
        </Grid>
      </Grid>
      <div className='title'>
        <h2>Obim prometa</h2>
        <Tooltip title={<Typography fontSize={14}>{totalPriceTooltipText}</Typography>}>
          <InfoOutlinedIcon />
        </Tooltip>
      </div>
      <Grid container spacing={1} alignItems='center' justifyContent='space-around'>
        <Grid item xs={12} sm={7} md={4}>
          <ReportBox
            title='Novogradnja'
            value='newTotalPrice'
            colorClass='new'
            eur={true}
            compact={true}
          />
        </Grid>
        <Grid item xs={12} sm={7} md={4}>
          <ReportBox
            title='Starogradnja'
            value='oldTotalPrice'
            colorClass='old'
            eur={true}
            compact={true}
          />
        </Grid>
        <Grid item xs={12} sm={7} md={4}>
          <ReportBox title='Ukupno' value='totalPrice' eur={true} compact={true} />
        </Grid>
      </Grid>
      <div className='title'>
        <h2>Kvadratura</h2>
        <Tooltip title={<Typography fontSize={14}>{areaTooltipText}</Typography>}>
          <InfoOutlinedIcon />
        </Tooltip>
      </div>
      <Grid container spacing={1} alignItems='center' justifyContent='space-around'>
        <Grid item xs={12} sm={7} md={4}>
          <ReportBox title='Novogradnja' value='newArea' colorClass='new' m2={true} />
        </Grid>
        <Grid item xs={12} sm={7} md={4}>
          <ReportBox title='Starogradnja' value='oldArea' colorClass='old' m2={true} />
        </Grid>
        <Grid item xs={12} sm={7} md={4}>
          <ReportBox title='Prosek' value='area' m2={true} />
        </Grid>
      </Grid>
    </div>
  );
}
